import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Rachel Caldwell" subtitle="Director" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "648px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/02.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABcBAQEBAQAAAAAAAAAAAAAAAAAEAgP/2gAMAwEAAhADEAAAAYnzqx0xEB95q655CsP/xAAbEAACAgMBAAAAAAAAAAAAAAABAgAREiEiA//aAAgBAQABBQI6jKympfXseMooslbDLRc9ZasT/8QAGREAAgMBAAAAAAAAAAAAAAAAAAEQESGB/9oACAEDAQE/AYTrDp//xAAaEQACAgMAAAAAAAAAAAAAAAAAAQIQESFR/9oACAECAQE/AalFPDRrh//EABwQAAEDBQAAAAAAAAAAAAAAAAABAhARITHB4f/aAAgBAQAGPwIo9IQ7O4apYwf/xAAcEAEAAwEBAAMAAAAAAAAAAAABACExEVFBgZH/2gAIAQEAAT8ho9fiDw8cTI+Q5Nr7Ogb2tTolLiZHvCnEekqIEfqX7H7Htfc//9oADAMBAAIAAwAAABCIyoL/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8Qy2UKZHZ//8QAGREAAwADAAAAAAAAAAAAAAAAAAERITGh/9oACAECAQE/ECMaoQWGvD//xAAfEAEAAgICAgMAAAAAAAAAAAABABEhQTFRYXGR0fH/2gAIAQEAAT8Qwt9FNrgnAyGWvT3DHMpi8RA40A88w1hobOTzXqBAHWYNY5irqAgTUoU2Ys+ZXrkWNfUQEFJ0x+xq1lLDQEh1Stqz/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "02",
              "title": "02",
              "src": "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/02.jpg",
              "srcSet": ["/static/aab8fa1fe87bdae2c110628bd148f522/f93b5/02.jpg 300w", "/static/aab8fa1fe87bdae2c110628bd148f522/b4294/02.jpg 600w", "/static/aab8fa1fe87bdae2c110628bd148f522/6e52e/02.jpg 648w"],
              "sizes": "(max-width: 648px) 100vw, 648px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`Pechersk School International Kyiv, is the only school in Ukraine fully authorised, since 2000, to offer the International Baccalaureate PYP, MYP and DP programmes. These programmes offer a continuum of education from Early Childhood (3 - 4 year olds) to Grade 12 (17 - 18 year olds).`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9a0fea7dae7c6f22028cded7f54f747e/536c7/01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAwklEQVQI1wG3AEj/APPy8+ny+Ovq1PfrxfXryPXuzfTv4+71++Pk5fLWzvHWz/LY0PHh3vX09eHv9dbo8tjq8tPp8tfq8+7w8wDt8fN/v+eurGz/xCnxvzf1xCr878qx4Pt3jKPqb1HrclToYT/yrpvi9v5nrNJEs9NKw90tt9ZXxODx8fUA7vDzuc7g4NXD8dy27dm37+C29/Xwyt3qxsPQ7cG/6MC/6b679Obj4uzztsrct9nmtdvostnm0ebu9PL0STmSrxagHoMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "01",
            "title": "01",
            "src": "/static/9a0fea7dae7c6f22028cded7f54f747e/c1b63/01.png",
            "srcSet": ["/static/9a0fea7dae7c6f22028cded7f54f747e/5a46d/01.png 300w", "/static/9a0fea7dae7c6f22028cded7f54f747e/0a47e/01.png 600w", "/static/9a0fea7dae7c6f22028cded7f54f747e/c1b63/01.png 1200w", "/static/9a0fea7dae7c6f22028cded7f54f747e/536c7/01.png 1480w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`A well-established programme in international schools for many decades, the IB is also the fastest growing curriculum in the world, in both international and national schools. The three IB programmes (the Primary Years, Middle Years, and Diploma) focus on developing deep conceptual understanding through the acquisition of knowledge, and development of skills and attitudes.`}</p>
    <p>{`At PSI, learning occurs in a nurturing, stimulating and joyful environment. We aim for every student to fulfill his or her potential through learning opportunities that are inquiry-driven and applied to real world contexts. Our IB teachers inspire students to become inquirers, knowledgeable, thinkers, communicators, principled, open-minded, caring, risk-takers, balanced and reflective learners. We value the development of personal skills and attitudes relevant to the learning needs of today and the challenges and opportunities of our world tomorrow. At PSI, we educate our students to be critical thinkers, problem-solvers and compassionate citizens who take positive action both within school and beyond. We prepare students for global citizenship by emphasising intercultural understanding and by participation in engagements that emphasise global issues.`}</p>
    <p>{`At PSI we value student agency and seek ways to include our students in the decision-making processes of the school. This happens within the classroom contexts and on a grander scale such as the inclusive process for creating our school’s core values. Our Student Councils and Student Advisory Group are important vehicles for developing leadership skills, for expressing creative ideas and for addressing issues identified by the student body.`}</p>
    <p>{`I am proud to serve this diverse, dynamic, caring and inspirational learning community.`}</p>
    <p><strong parentName="p">{`Rachel Caldwell`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Director`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`PSI, Kyiv, Ukraine`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      